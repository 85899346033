import * as API from '@/services/API';

export default {
    getResources () {
        return API.apiClient.get('/resources');
    },
    getResource (id) {
        return API.apiClient.get(`/resources/${id}`);
    },
    getLatestResources () {
        return API.apiClient.get('/resources/latest');
    },
    getCategoryResources (category) {
        return API.apiClient.get(`/resources/category/${category}`);
    },
    getTypeResources (type) {
        return API.apiClient.get(`/resources/type/${type}`);
    },
    search (query) {
        return API.apiClient.get(`/resources/search/${query}`);
    }
};
