<template>
<div ref="modalResourceAdvice" class="phone-modal phone-modal-port-search with-nav" style="bottom: -779px;" :class="{active: isResourceAdviceModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<BookIcon style="width: 25px; height: auto;" class="icon book-icon" />
			<span v-if="title">{{ title }}</span>
			<span v-else>Seek Advice</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closeResourceAdviceModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="text-block section-gap">
		<p class="no-space"><strong>Contact us</strong></p>
		<p class="no-space">Please feel free to contact us.</p>
		<!-- <p class="no-space">We have a list of Frequently Asked Questions for you to look through. Should these not answer your query, please feel free to contact us.</p> -->
	</div>
	<div class="section-gap none-bottom card-wrap">
		<Card
			v-if="online"
	        link="https://www.missiontoseafarers.org/contact-a-chaplain"
	        class="card-block-blue card-block-full"
	        icon="ChatIcon"
	        category="Flying Angel"
	        title="Speak to the flying angel"
	        text="Our interactive digital assistant"
	    />
		<!-- <Card
			routeName="ResourcesFaqs"
			class="card-block-grey card-block-full"
			icon="FaqIcon"
			category="Faqs"
			title="Frequently Asked Questions"
			text="A list of the most frequently asked questions"
            @click.native="closeResourceAdviceModal"
		/> -->
	</div><!-- card wrap -->
</div><!-- modal -->
</template>

<script>
import BookIcon from '@/assets/book.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import Card from '@/components/Card.vue';
export default {
name: 'ResourceAdviceOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {};
	},
	components: {
		BookIcon,
		GreyCloseIcon,
		Card
	},
    computed: {
        isResourceAdviceModalOpen () {
            return this.$store.state.isResourceAdviceModalOpen;
        },
        online () {
            return window.navigator.onLine;
        }
    },
	methods: {
        closeResourceAdviceModal () {
        	this.$store.commit('RESOURCE_ADVICE_TOGGLE');
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-title-wrap .section-title {
		color: $grey;
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $red_dark;
				}
			}
			span {
				color: $red_dark;
			}
		}
	}
	.card-wrap {
		.card-block-blue {
			margin-bottom: 15px;
		}
	}
</style>
